import React, { useRef, useEffect } from 'react';
import healthVyu from '../api/healthVyu';
import './login.css';
import { useDispatch, useSelector } from 'react-redux';
import { LoginFailure, LoginStart, LoginSuccess } from '../redux/actions';
import { useNavigate, Link } from "react-router-dom";

function Login() {
    const email = useRef();
    const password = useRef();
    const { user, isFetching, error} = useSelector(state => state.auth);
    
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if(user != null)
            navigate('/');
    },[]);

    const submit = async (e) =>{
        e.preventDefault();

        dispatch(LoginStart());
        try{
            const userCredentials = {
                email: email.current.value,
                password: password.current.value
            }
            const res = await healthVyu.post('/api/login',userCredentials);
            dispatch(LoginSuccess(res.data));
            navigate("/");
        }catch(err){
            dispatch(LoginFailure(err.response.data));
        }
    }
    
    return(
        <div className='loginContainer'>
            <div className="loginHeading">Login</div>
            <form className='loginForm' onSubmit={submit}>
                {error && <div className='error'>{error}</div>}
                <div className="formInputContainer">
                    <label>Email</label>
                    <input className = 'formInputField' 
                        type="text"
                        ref={email} 
                        placeholder='Enter your email'
                        required
                    />
                </div>               
                <div className="formInputContainer">
                    <label>Password</label>
                    <input className = 'formInputField' 
                        type="password" 
                        ref={password}
                        placeholder='Enter password'
                        required
                    />
                </div>
                <button className='loginSubmit' type='submit'>
                    {isFetching ? "Loading" : "Login"}
                </button>
                <Link to='/register' className='formLink'>
                    Don't have an account ? Register Here
                </Link>
                <Link to='/' className='formLink'>
                    Continue as a Guest
                </Link>
            </form>
        </div>        
    )
}

export default Login;