import './workoutsCard.css';
import React from "react";

function WorkoutsCard(){
    return (
        <div className="workoutsCardContainer">
            <img src={`../assets/workouts/${"w1.jpg"}`} loading="lazy" alt="" onError={(e)=>e.target.src='../assets/gym.jpg'} className="workoutsCardImg"/>
            <div className="workoutsCardDetails">
                <h1>Russian Squat Program</h1>
                <h2>
                The Russian Squat Program is a six-week regimen emphasizing frequent, progressive overload squatting 
                to enhance strength and power.
                </h2>
            </div>
        </div>
    );
}

export default WorkoutsCard;