import './blogs.css';
import React, { useState } from 'react';
import SideBar from '../../components/sideBar/sideBar';
import Header from '../../components/header/header';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { sideBarState } from '../../redux/actions';
import BlogsCard from './blogsCard';

function Blogs(){

    const listAllBlogs = [
        {
            id: 1,
            tag: "Corporate",
            heading: "Understanding the aspects of fitness to achieve True Happiness in Corporate Lifestyle",
            subHeading: "The blog emphasizes the significance of fitness in achieving happiness within a corporate lifestyle. It offers insights on balancing 3 major aspects of fitness.",
            author: "Vishal Malik",
            color: "blue",
            img: "blog_0001/b1.jpg",
            route: "/blogs/Understanding-the-aspects-of-fitness-to-achieve-True-Happiness-in-Corporate-Lifestyle"
        },
        {
            id: 2,
            tag: "Nutrition",
            heading: "Top 10 Protein-Packed Foods for Vegetarians and Non-Vegetarians",
            subHeading: "Explore the top 10 protein-rich foods for vegetarians and non-vegetarians, detailing their protein content, nutritional benefits, and how to incorporate them into your diet.",
            author: "Vishal Malik",
            color: "yellow",
            img: "blog_0002/b1.jpg",
            route: "/blogs/Top-10-Protein-Packed-Foods-for-Vegetarians-and-Non-Vegetarians"
        },
        {
            id: 3,
            tag: "Lifestyle",
            heading: "Unlocking the Power of Sleep for Optimal Health and Peak Performance",
            subHeading: "Discover how prioritizing sleep can boost your health and fitness. Learn the science behind sleep, its impact on performance, and tips for better rest to achieve peak physical and mental well-being.",
            author: "Vishal Malik",
            color: "blue",
            img: "blog_0003/b1.jpg",
            route: "/blogs/Unlocking-the-Power-of-Sleep-for-Optimal-Health-and-Peak-Performance"
        },
        {
            id: 4,
            tag: "Environment",
            heading: "Effect of Climate Change on Health",
            subHeading: "Harmful effects of climate change on health and best practices to be health even in these climate conditions",
            author: "Vishal Malik",
            color: "green",
            img: "climate.jpg"
        },
        {
            id: 5,
            tag: "Sports",
            heading: "Do sports at home",
            subHeading: "Best sports to do at home is anything that you like.",
            author: "Vishal Malik",
            color: "red",
            img: "sports.jpg"
        },
        {
            id: 6,
            tag: "Technology",
            heading: "Effects of Technology on health",
            subHeading: "Find the harmful effects of technology on health and best practices to prevent them.",
            author: "Vishal Malik",
            color: "blue",
            img: "technology.jpg"
        },
        {
            id: 7,
            tag: "Environment",
            heading: "Effect of Climate Change on Health",
            subHeading: "Harmful effects of climate change on health and best practices to be health even in these climate conditions",
            author: "Vishal Malik",
            color: "green",
            img: "climate.jpg"
        }
    ]

    const dispatch = useDispatch();
    const isOpen = useSelector((state)=>state.sideBarState);

    const [filteredBlogs, setFilteredBlogs] = useState(listAllBlogs);
    const [filterBtn, setFilterBtn] = useState('All');

    const filterBlogs = (tag) => {
        setFilterBtn(tag);
        if(tag==='All'){
            setFilteredBlogs(listAllBlogs);
            return;
        }
        const filterByType = listAllBlogs.filter((b) => {
            if (b.tag.toLowerCase() === tag.toLowerCase())
            { 
                return b; 
            }
            return null;
        });

        setFilteredBlogs(filterByType);
    }

    return(
        <div className="blogsContainer">
            {isOpen && <div className="overlay" onClick={()=>dispatch(sideBarState(!isOpen))}/>}
            <div className="header">   
                <Header/>
            </div>
            <div className='blogsContent'>
                <SideBar/>
                <div className='blogsFiltersList'>
                    <div className="blogsFiltersScroll">
                        <div className="blogsFilters">
                            <div className={`blogsFilterButton sz_16 wt_5 ${filterBtn === 'All' && "selected"}`} onClick={()=>filterBlogs('All')}>All</div>
                            <div className={`blogsFilterButton sz_16 wt_5 ${filterBtn === 'Sports' && "selected"}`} onClick={()=>filterBlogs('Sports')}>Sports</div>
                            <div className={`blogsFilterButton sz_16 wt_5 ${filterBtn === 'Nutrition' && "selected"}`} onClick={()=>filterBlogs('Nutrition')}>Nutrition</div>
                            <div className={`blogsFilterButton sz_16 wt_5 ${filterBtn === 'Environment' && "selected"}`} onClick={()=>filterBlogs('Environment')}>Environment</div>
                            <div className={`blogsFilterButton sz_16 wt_5 ${filterBtn === 'Technology' && "selected"}`} onClick={()=>filterBlogs('Technology')}>Technology</div>
                            <div className={`blogsFilterButton sz_16 wt_5 ${filterBtn === 'Corporate' && "selected"}`} onClick={()=>filterBlogs('Corporate')}>Corporate</div>
                        </div>
                    </div>
                    <div className="blogsList">
                        {filteredBlogs.map((b)=>(
                            <Link to={b.route} className="blogsLinks">
                                <BlogsCard key={b.id} blog={b}/> 
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Blogs; 