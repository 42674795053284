import React, { useRef, useState } from 'react';
import {useNavigate, Link} from 'react-router-dom';
import healthVyu from '../api/healthVyu';
import './register.css';

function Register() {
    const name = useRef('');
    const email = useRef('');
    const password = useRef('');
    const otp = useRef('');
    const passwordAgain = useRef('');
    const [flag, setFlag] = useState(false);
    const [isOtpSent, setIsOtpSent] = useState(false); 
    const [errors, setErrors] = useState({});

    const navigate = useNavigate();

    const verify = async(e) => {
        e.preventDefault();
        setFlag(true);
        if(validateForm()){
            const user={
                name: name.current.value,
                email: email.current.value,
                password: password.current.value
            }
            try{
                await healthVyu.post('/api/send-otp', user);
                setIsOtpSent(true);
                setErrors({});
            }catch(err){
                setErrors({api: err.response.data});
            }   
        }
    }

    const submit = async (e) =>{
        e.preventDefault();
        if(validateForm()){
            const user={
                name: name.current.value,
                email: email.current.value,
                password: password.current.value,
                otp: otp.current.value
            }
            try{
                await healthVyu.post('/api/register', user);
                navigate("/login");
            }catch(err){
                setErrors({api:err.response.data});
            } 
        }
    }

    const validateForm = () => {
        const newErrors = {};
        if(!name.current.value){
            newErrors.name = "Name is required"
        }
        else if(name.current.value.trim().replace(" ","").length < 3){
            newErrors.name = "Name must be atleast 3 characters long"
        }
        
        if (!email.current.value) {
            newErrors.email = 'Email is required';
        } else if (!isValidEmail(email.current.value)) {
            newErrors.email = 'Invalid email format';
        }

        if (!password.current.value) {
            newErrors.password = 'Password is required';
        } else if (password.current.value.length < 6) {
            newErrors.password = 'Password must be at least 6 characters long';
        }

        if (!passwordAgain.current.value) {
            newErrors.passwordAgain = 'Confirm Password is required';
        } else if(passwordAgain.current.value !== password.current.value){
            newErrors.passwordAgain = "Passwords don't match"
        }

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;

    }

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    
    return(
        <div className='registerContainer'>         
            <div className="registerHeading">Register</div>
            <form className='registerForm'>
                {errors.api && <div className='error'>{errors.api}</div>}
                <div className="formInputContainer">
                    <label>Name</label>
                    {errors.name && <div className='error'>{errors.name}</div>}
                    <input className = 'formInputField' 
                        type="text" 
                        ref={name}
                        placeholder='Your name'
                        onChange={flag && validateForm}
                        readOnly={isOtpSent}
                    />
                </div>
                <div className="formInputContainer">
                    <label>Email</label>
                    {errors.email && <div className='error'>{errors.email}</div>}
                    <input className = 'formInputField' 
                        type="text" 
                        ref={email}
                        placeholder='Email'
                        onChange={flag && validateForm}
                        readOnly={isOtpSent}
                    />
                </div>
                <div className="formInputContainer">
                    <label>Password</label>
                    {errors.password && <div className='error'>{errors.password}</div>}
                    <input className = 'formInputField' 
                        type="password" 
                        ref={password}
                        placeholder='Password'
                        onChange={flag && validateForm}
                        readOnly={isOtpSent}
                    />
                </div>
                <div className="formInputContainer">
                    <label>Confirm Password</label>
                    {errors.passwordAgain && <div className='error'>{errors.passwordAgain}</div>}
                    <input className = 'formInputField'
                        type="password" 
                        ref={passwordAgain}
                        placeholder='Re-enter Password'
                        onChange={flag && validateForm}
                        readOnly={isOtpSent}
                    />
                </div>
                {
                    isOtpSent && 
                    <div className="formInputContainer">
                        <label>OTP</label>
                        <input className = 'formInputField'
                            type="password" 
                            ref={otp}
                            placeholder='Enter OTP'
                            required
                        />
                    </div>
                }
                {
                    isOtpSent ?
                    <button className='registerSubmit' onClick={submit}>
                        Verify
                    </button>
                    :
                    <button className='registerSubmit' onClick={verify}>
                        Register
                    </button>
                }
                <Link to='/login' className='formLink'>
                    Already have an account ? Sign In
                </Link>
                <Link to='/' className='formLink'>
                    Continue as a Guest
                </Link>
            </form>
        </div>        
    )
}

export default Register;