
// export const fetchGyms = () =>  async (dispatch) => {
//     const response = await healthVyu.get('/api/clubs');
//     dispatch({
//         type: 'FETCH_GYMS',
//         payload: response.data
//     });
// };


export const fetchGyms = (gyms) => {
    return {
        type: 'FETCH_GYMS',
        payload: gyms
    }
};

export const filteredGyms = (gyms) => {
    return {
        type: 'FILTERED_GYMS',
        payload: gyms
    }
}

export const sideBarState = (isOpen) => {
    return {
        type: 'SIDE_BAR_STATE',
        payload: isOpen
    }
}

export const LoginStart = () => {
    return {
        type: 'LOGIN_START'
    }
}

export const LoginSuccess = (user) => {
    localStorage.setItem('user', JSON.stringify(user));
    return {
        type: 'LOGIN_SUCCESS',
        payload: user
    }
}

export const LoginFailure = (error) => {
    return {
        type: 'LOGIN_FAILURE',
        payload: error
    }
}

export const Logout = () => {
    // Clear cookies
    document.cookie.split(";").forEach((c) => {
        document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });

    // Clear local storage
    localStorage.clear();
    window.location.reload();
    return {
        type: 'LOGOUT'
    }
}
// export const fetchGyms = () => {

//     return {
//         type: 'FETCH_GYMS',
//         payload: response
//     };
// };