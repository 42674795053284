import './blogsFooter.css';
import React from 'react';
import {Link} from 'react-router-dom';

function BlogsFooter(){
    return (
        <div className="blogsFooterContainer">
            <div className="blogsFooterUpper">
                <div className="blogsFooterContactExplore">
                    <h3>Contact Us</h3>
                    <span style={{color: "var(--primary-3)"}}>support@healthvyu.com</span>
                    <br/>
                    <h3>Terms & Privacy</h3>
                    <Link to='/privacy-policy' className='blogsFooterLinks'>Privacy Policy</Link>
                </div>
                <div className="blogsFooterContactExplore">
                    <h3>Explore</h3>
                    <Link to='/' className='blogsFooterLinks'>Home</Link>
                    <Link to='/blogs' className='blogsFooterLinks'>Blogs</Link>
                    <Link to='/workouts' className='blogsFooterLinks'>Workouts</Link>
                </div>
            </div>
            <div className="blogsFooterLower">
                <span className='text sz_18'>© 2024 | HealthVyu</span>
            </div>
        </div>
    );
}

export default BlogsFooter;