import './tools.css';
import React, { useState } from 'react';
import SideBar from '../../components/sideBar/sideBar';
import Header from '../../components/header/header';
import { useDispatch, useSelector } from 'react-redux';
import { sideBarState } from '../../redux/actions';
import {BMI, BMR} from '../../utils/icons';
import { Link } from 'react-router-dom';

function Tools() {

    const dispatch = useDispatch();
    const isOpen = useSelector((state)=>state.sideBarState);

    return (
        <div className='toolsContainer'>
            {isOpen && <div className="overlay" onClick={()=>dispatch(sideBarState(!isOpen))}/>}
            <div className="header">   
                <Header/>
            </div>
            <div className='toolsContent'>
                <SideBar/>
                <div className='toolsCalculators'>
                    <Link to='/tools/bmi' className='linkTo'>
                        <div className="toolsCalc">
                            <BMI H='100' W='100' F='var(--secondary-1)'/>
                            <span>Check BMI</span>
                        </div>
                    </Link>
                    <Link to='/tools/bmr' className='linkTo'>
                        <div className="toolsCalc">
                            <BMR H='100' W='100' F='var(--secondary-1)'/>
                            <span>Check BMR</span>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Tools;