import './textInput.css';
import React from "react";

function TextInput({H, W, label, onChange}){

    return(
        <div>
            <div class="textInput" style={{height:`${H}px`, width:`${W}px` }}>
                <input 
                    type="number" 
                    onChange={onChange}
                    step="0.01"
                    required
                />
                <span>{label}</span>
            </div>
        </div>
    )
};

export default TextInput;