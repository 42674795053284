import React, {useState} from 'react';
import './header.css';
import { Logout, filteredGyms } from '../../redux/actions';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { sideBarState } from '../../redux/actions';
import {Notif, Settings, User, Search, Menu, Back, Logo, Close} from '../../utils/icons';
import { SearchResults } from '../searchEngine';
import { AllPaths } from '../../paths';

function Header() {

    const dispatch = useDispatch();
    const gyms = useSelector((state) => state.gyms);
    const isOpen = useSelector((state)=>state.sideBarState);
    const user = useSelector((state)=>state.auth.user);
    const [searchBarState, setSearchBarState] = useState(false);
    const [userDropDown, setUserDropDown] = useState(false);

    const [searchResults, setSearchResults] = useState([]);

    const handleSearch = (searchVal) => {
        if(searchVal === "" || searchVal == null){
            setSearchResults([]);
            return;
        }
        const filterBySearch = AllPaths().filter((c) => {
            if ((c.title.toLowerCase().includes(searchVal.toLowerCase()))) 
            { 
                return c; 
            }
        })
        if(filterBySearch.length == 0) setSearchResults([{title: "no result found"}]);
        else setSearchResults(filterBySearch);
    }

    const clearSearch = () =>{
        setSearchBarState(false);
        setSearchResults([]);
    }
    
    return ( 
        <div>
            <div className="headerContainer">
                <div className="headerLogo">
                    <Logo className='logoH'/>
                    <div className="logoHText">
                        <div>ealthVyu</div>
                        <div className='logoHSlang'>fitness haven</div>
                    </div>
                </div>
                <div className="headerSearchBar">
                    <input className="headerSearchInputBox" 
                        placeholder='Search something here...'
                        onChange={(e) => handleSearch(e.target.value)}
                    />
                    <Search className='headerSearchIcon'/>
                    {
                        searchResults.length > 0 &&
                        <div className='headerSearchResults'>
                            {
                                searchResults.map((sr)=>(
                                    <Link to={sr.path} className='headerSearchResultLinks'>
                                        {sr.title}
                                    </Link>
                                ))
                            }
                        </div>
                    }
                </div>
                <div className="headerMenu">
                    <div className="headerMenuButtons">
                        <Notif className='headerMenuIcon'/>
                    </div>
                    <div className="headerMenuButtons">
                        <Settings className='headerMenuIcon'/>
                    </div>
                    <div className="headerMenuButtons" onClick={()=>setUserDropDown(!userDropDown)}>
                        <User className='headerMenuIcon'/>
                        {
                            userDropDown &&
                            <div className="userDropDown">
                                <div className='userInfo'>Hi, { user === null ? "Guest" : user.name}</div>
                                {
                                    user === null ?
                                    <div className="dropDownItems">
                                        <Link to='/login' className='dropDownItemOption'>
                                            Log In
                                        </Link>
                                        <Link to='/register' className='dropDownItemOption'>
                                            Register
                                        </Link>
                                    </div>
                                    :
                                    <div className="dropDownItems">
                                        <div className='dropDownItemOption' onClick={()=>dispatch(Logout())}>
                                            Logout
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="headerContainerSS">
                {/* <img src="../assets/icons/menu.svg" alt="" className='headerIcon'/> */}
                <div onClick={()=>dispatch(sideBarState(!isOpen))} className='headerIcon'>
                    <Menu H="24" W="24" style={{fill: "var(--primary-1)"}}/>
                </div>
                <div className={`headerLogo ${searchBarState && "hide"}`}>
                    <Logo className='logoH'/>
                    <div className="logoHText">
                        <div>ealthVyu</div>
                        <div className='logoHSlang'>fitness haven</div>
                    </div>
                </div>
                {
                    searchBarState &&
                    <div className="headerSearchBar">
                        <input className="headerSearchInputBox" 
                            placeholder='Search something here...'
                            onChange={(e) => handleSearch(e.target.value)}
                        />
                        <Search className='headerSearchIcon'/>
                        {
                            searchResults.length > 0 &&
                            <div className='headerSearchResults'>
                                {
                                    searchResults.map((sr)=>(
                                        <Link to={sr.path} className='headerSearchResultLinks'>
                                            {sr.title}
                                        </Link>
                                    ))
                                }
                            </div>
                        }
                    </div>
                }
                {
                    !searchBarState ?
                    <div className='headerIcon' onClick={()=>setSearchBarState(true)}>
                        <Search H="24" W="24"/>
                    </div>
                    :
                    <div className='headerIcon' onClick={()=>clearSearch()}>
                        <Close H="30" W="30"/>
                    </div>
                }
            </div>   
        </div>  
     );
}

export default Header;
