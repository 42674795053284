import './bmiCalculator.css';
import React, { useRef, useState } from 'react';
import SideBar from '../../../components/sideBar/sideBar';
import Header from '../../../components/header/header';
import { useDispatch, useSelector } from 'react-redux';
import { sideBarState } from '../../../redux/actions';
import { Back } from '../../../utils/icons';
import { Link } from 'react-router-dom';

function BmiCalculator() {

    const dispatch = useDispatch();
    const isOpen = useSelector((state)=>state.sideBarState);

    const [bmi, setBmi] = useState();
    const [weightUnit, setWeightUnit] = useState('kg');
    const [heightUnit, setHeightUnit] = useState('cm');
    
    const height = useRef();
    const weight = useRef();

    const calculateBMI = (e) => {
        let h = Number(height.current.value);
        let w = Number(weight.current.value);

        if(weightUnit === 'lbs') w=w*0.453592;
        if(heightUnit === 'in') h=h*2.54;

        let b = (w*100*100) / (h*h);
        setBmi(b.toFixed(2));
        e.preventDefault();
    }

    return (
        <div className='bmiCalcContainer'>
            {isOpen && <div className="overlay" onClick={()=>dispatch(sideBarState(!isOpen))}/>}
            <div className="header">   
                <Header/>
            </div>
            <div className='bmiCalcContent'>
                <SideBar/>
                <div className="bmiCalcFormContainer">
                    <div className='toolsBack'>
                        <Link to='/tools' className='calcLink'>
                            <Back className='toolsBackIcon'/> back to tools
                        </Link>
                    </div>
                    <div className="bmiCalcHeading">BMI Calculator</div>
                    <div className='bmiInfo'>
                        Body Mass Index (BMI) is a widely used measurement that assesses an individual's body weight relative to their height. 
                        It is calculated by dividing a person's weight in kilograms by the square of their height in meters. 
                        BMI categorizes individuals into weight ranges, such as underweight, normal weight, overweight, and obesity. 
                        While BMI is a useful screening tool, it has limitations and does not account for factors like muscle mass or body composition. 
                        It is recommended to consult with a healthcare professional to evaluate overall health, as additional factors and assessments are necessary for a comprehensive evaluation.
                    </div>
                    <form className='checkBmiForm'>
                        <div className='formInputContainer'>
                            <label className='bmiFormLabel'>
                                Height
                                <label className='bmiFormRadioButton'>
                                    cm
                                    <input type="radio" value="cm"
                                        checked={heightUnit === 'cm'}
                                        onChange={()=>setHeightUnit('cm')}
                                    />
                                </label>
                                <label className='bmiFormRadioButton'>
                                    in
                                    <input type="radio" value="in"
                                        checked={heightUnit === 'in'}
                                        onChange={()=>setHeightUnit('in')}
                                    />
                                </label>    
                            </label>
                            <input className = 'formInputField' 
                                type="number"
                                step="0.01" 
                                ref={height}
                                placeholder={heightUnit}
                                required
                            />
                        </div>
                        <div className='formInputContainer'>
                            <label className='bmiFormLabel'>
                                Weight
                                <label className='bmiFormRadioButton'>
                                    kg
                                    <input type="radio" value="kg"
                                        checked={weightUnit === 'kg'}
                                        onChange={()=>setWeightUnit('kg')}
                                    />
                                </label>
                                <label className='bmiFormRadioButton'>
                                    lbs
                                    <input type="radio" value="lbs"
                                        checked={weightUnit === 'lbs'}
                                        onChange={()=>setWeightUnit('lbs')}
                                    />
                                </label>
                            </label>
                            <input className = 'formInputField' 
                                type="number"
                                step="0.01" 
                                ref={weight}
                                placeholder={weightUnit}
                                required
                            />
                        </div>
                        <button className='bmiFormButton' onClick={calculateBMI}>Check BMI</button>
                        { bmi && <div className='bmiValue'>{bmi}</div>}
                    </form>
                    <div className="bmiChart">
                        <div className="bmiChartCategory">
                            <div className="bmiChartValues" style={{backgroundColor: 'var(--primary-5)', fontWeight: '500', color: 'white'}}>CATEGORY</div>
                            <div className="bmiChartValues" style={{backgroundColor: 'var(--secondary-1)'}}>Underweight</div>
                            <div className="bmiChartValues" style={{backgroundColor: 'var(--secondary-2)'}}>Normal range</div>
                            <div className="bmiChartValues" style={{backgroundColor: 'var(--secondary-3)'}}>Overweight</div>
                            <div className="bmiChartValues" style={{backgroundColor: 'var(--secondary-4)'}}>Obese</div>
                        </div>
                        <div className="bmiChartRange">
                            <div className="bmiChartValues" style={{backgroundColor: 'var(--primary-5)', fontWeight: '500', color: 'white', textAlign: 'center'}}>RANGE</div>
                            <div className="bmiChartValues" style={{backgroundColor: 'var(--secondary-1)', textAlign: 'center'}}>Below 18.4</div>
                            <div className="bmiChartValues" style={{backgroundColor: 'var(--secondary-2)', textAlign: 'center'}}>18.5 – 24.9</div>
                            <div className="bmiChartValues" style={{backgroundColor: 'var(--secondary-3)', textAlign: 'center'}}>25.0 – 29.9</div>
                            <div className="bmiChartValues" style={{backgroundColor: 'var(--secondary-4)', textAlign: 'center'}}>Above 30.0</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
    
}

export default BmiCalculator;