import './mainWrapper.css';
import React from "react";
import Header from '../header/header';
import SideBar from '../sideBar/sideBar';
import { useDispatch, useSelector } from 'react-redux';
import { sideBarState } from '../../redux/actions';

function MainWrapper({component}){

    const dispatch = useDispatch();
    const isOpen = useSelector((state)=>state.sideBarState);

    return (
        <div className="wrapperContainer">
            {isOpen && <div className="overlay" onClick={()=>dispatch(sideBarState(!isOpen))}/>}
            <div className="header">   
                <Header/>
            </div>
            <div className='wrapperContent'>
                <SideBar/>
                {component}
            </div>
        </div>
    );
}

export default MainWrapper;