import BlogsHeader from '../../../../components/blogsHeader/blogsHeader';
import SideBar from '../../../../components/sideBar/sideBar';
import '../blogsContent.css';
import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import { sideBarState } from '../../../../redux/actions';
import BlogsFooter from '../../../../components/blogsFooter/blogsFooter';

function Blog_0001(){

    const dispatch = useDispatch();
    const isOpen = useSelector((state)=>state.sideBarState);

    return(
        <div className="blogContainer">
            <BlogsHeader/>
            {isOpen && <div className="blogsSideBarOverlay" onClick={()=>dispatch(sideBarState(!isOpen))}/>}
            <div className="blogsSideBar">
                <SideBar/>
            </div>
            <div className="blogParas">
                <h1>Understanding the aspects of fitness to achieve True Happiness in Corporate Lifestyle</h1>
                <div className="blogsImgContainer">
                    <img src="../assets/blogs/blog_0001/b1.jpg" loading="lazy" alt="" onError={(e)=>e.target.src='../assets/gym.jpg'} className="blogsImg"/>
                </div>
                <p>
                    In today's fast-paced corporate world, the pursuit of success often comes at the expense of our health. 
                    Long hours at the office, endless meetings, and the constant pressure to perform can make it challenging 
                    to prioritize fitness. However, neglecting our well-being can have serious consequences, both physically 
                    and mentally. That's why it's essential to find ways to integrate fitness into our corporate lifestyle 
                    effectively. In this blog, we'll explore strategies for cultivating fitness amidst the demands of the 
                    corporate world.
                </p>
                <p>This blog consists of 7 sections:
                    <ol>
                        <li>What is the meaning of fitness?</li>
                        <li>Why Fitness Matters?</li>
                        <li>Quitting bad habits</li>
                        <li>True Freedom</li>
                        <li>6 Steps to achieve all aspects of fitness</li>
                        <li>Example Schedule</li>
                        <li>Closing Remarks</li>
                    </ol>
                </p>


                <h2>
                    What is the meaning of fitness?
                </h2>
                <p>
                    First things first, let's talk about what fitness really means. Fitness isn't just about being strong 
                    or having stamina; it's about being healthy in every way - physically, mentally, and emotionally. 
                    It's about having the energy and strength to handle everyday life and do things that make you happy.
                </p>
                <p>Fitness consists of three major aspects:
                    <ol>
                        <li>Physical health</li>
                        <li>Mental health</li>
                        <li>Emotional health</li>
                    </ol> 
                </p>
                <p>    
                    Each aspect is equally important, like the legs of a stool. If one part isn't strong, the whole thing 
                    can fall apart. It's crucial to take care of all three to stay healthy. Some people manage to do this 
                    really well, and they're usually the happiest and most confident.<br/><br/>
                    I've seen different types of people: some are super strong but not very smart, others are really smart 
                    but always sad, and some are always happy but struggle with their weight. They all lack something in their 
                    fitness, which makes it hard for them when things get tough.
                    Now, you might think it's hard to stay fit in all these ways, but it's actually not that tough. 
                    Yes, it's true! It's pretty simple, and I'll explain how in the next sections.
                </p>

                <div className="blogsImgContainer">
                        <img src="../assets/blogs/blog_0001/b5.jpg" loading="lazy" alt="" onError={(e)=>e.target.src='../assets/gym.jpg'} className="blogsImg"/>
                </div>
                <h2>
                    Why Fitness Matters ?
                </h2>
                <p>
                    This part is for people who wonder why it's important to be fit, why bother putting effort into your body, 
                    and why this and why that. Let me be honest with you, thinking like this shows that you might be lazy, 
                    not very active, and not living a healthy life.
                    My words might be tough to hear, but it's the truth. We never question why we need to eat, drink, or sleep, 
                    but when it comes to exercising or doing anything to stay fit, we start asking lots of questions. Before you start any fitness routine, it's important to understand this: "Staying fit is just as important as eating, drinking water, or breathing."
                    And you know what? It's actually pretty easy to stay fit. Yes, it really is. Think about it:    
                </p>
                <p>
                    <ol>
                        <li>Spend 45-60 minutes doing some physical activity for your body.</li>
                        <li>Read for 30 minutes to keep your mind healthy.</li>
                        <li>Take some time to meditate or talk to your family and friends for your emotional well-being.</li>
                    </ol>
                </p>
                <p>
                    That's all it takes to stay fit. If you're asking why you should do these things, then you might be really 
                    lazy and not motivated. The simple answer to why fitness matters is the same as why anything else matters: 
                    if it makes you happier, then it's important. If something brings you joy, then it's important.
                </p>
                <p>
                <div className='blogsQuotes'>
                    “If you want to truly live the life to its fullest and wants to achieve true happiness,
                    then you need to incorporate fitness activities in your lifestyle”
                </div>
                </p>

                <div className="blogsImgContainer">
                    <img src="../assets/blogs/blog_0001/b3.jpg" loading="lazy" alt="" onError={(e)=>e.target.src='../assets/gym.jpg'} className="blogsImg"/>
                </div>
                <h2>
                    Quitting bad habits
                </h2>
                <p>
                    When I say quit bad habits, I don’t mean, like waking up late, procrastinating etc. No, I’m talking about 
                    the elephant in the room, and that is, Smoking & Excessive Drinking. Smoking and drinking has been 
                    normalized as a part of corporate culture. The fact that these types of activities has been normalized 
                    shows that harmful habits are accepted and even encouraged in corporate culture. This needs to be changed. 
                    Smoking and drinking are the habits that not only take a toll on your physical health but also have 
                    detrimental effects on your mental and emotional well-being. These habits crush each and every aspect of 
                    fitness. If you have any of these habits, you need to quit them immediately. Yeah, quitting these habits 
                    take a lot of courage and will power, but believe me, once you are successful, you will thank yourself for 
                    the rest of your life. The fruit is too sweet to ignore.
                </p>


                <div className="blogsImgContainer">
                    <img src="../assets/blogs/blog_0001/b4.jpg" loading="lazy" alt="" onError={(e)=>e.target.src='../assets/gym.jpg'} className="blogsImg"/>
                </div>
                <h2>
                    True Freedom
                </h2>
                <p>
                    To give up bad habits and focus on getting fit, you need to be disciplined. Some of you might argue, 
                    "Life's too short to be disciplined," or "Why waste time on exercising?" Let me tell you something: true 
                    freedom in life comes from discipline. As the famous marathon runner Eliud Kipchoge once said, 
                </p>
                <p>
                    <div className="blogsQuotes">
                        "Only the disciplined ones are free in life. If you are undisciplined, you are a slave to your moods, 
                        you are a slave to your passions."    
                    </div>
                </p>
                <p>    
                    It's true. Without discipline, you'll never feel truly satisfied. What makes you happy today might bore you 
                    tomorrow, and the cycle goes on. You won't find real happiness that way. If you want to experience genuine 
                    happiness, you need to develop discipline, form good habits, and start working on all aspects of fitness.
                </p>

                <h2>
                    6 Steps to achieve all aspects of fitness
                </h2>
                <p>
                    Now that we've talked a lot about fitness, let's discuss how to achieve it. It's actually quite simple. 
                    Here are some good habits to follow:
                
                    <ol>
                        <li>
                            <b>Find a job with a good work-life balance:</b> Look for a job where you don't have to work crazy hours. 
                            If your job takes up more than 12 hours of your day, including travel and getting ready time,
                            consider finding something else. You need to prioritize work life balance over money.
                            <p><div className="blogsQuotes">
                                "Most of the people’s problem will be solved when they realize that money is not everything, but health is”    
                            </div></p>
                        </li>

                        <li>
                            <b>Exercise for at least 45 minutes every day:</b> You don't have to go to the gym every day, but you 
                            should do some kind of physical activity. It could be yoga, cycling, playing a sport you love, 
                            walking briskly, running, or dancing. Mixing weight training with cardio is a good idea. 
                            Aim for weight training three times a week, cardio twice a week, and some low-intensity activity 
                            on rest days.
                        </li>

                        <li>
                            <b>Read for at least 30 minutes every day:</b> Reading is great for your mental health. Pick books that 
                            teach you something new and expand your knowledge. You can still read novels for fun, but try to 
                            prioritize books that make you smarter.
                        </li>

                        <li>
                            <b>Take care of your emotional health:</b> Don't ignore your emotions. Practice mindfulness, write about 
                            your feelings, spend time with loved ones, and talk about your feelings with them. These things 
                            help keep your emotions in check.
                        </li>

                        <li>
                            <b>Balanced Diet:</b> Ensuring proper nutrition is paramount for maintaining overall fitness. It's essential to 
                            maintain a well-balanced diet that includes sufficient protein and omega-3 fatty acids. 
                            Protein is vital for physical health, while omega-3 supports mental well-being.
                            In today's times, there's often a focus on high protein intake while omega-3 is overlooked. 
                            It's crucial to recognize that just as protein is essential after a strenuous workout, omega-3 
                            is necessary for nourishing the brain, which operates continuously. Hence, feeding your brain 
                            is as important as nourishing your body.
                            I recommend consuming protein within the range of 1.2 to 1.8 grams per kilogram of your body 
                            weight, along with a daily intake of at least 2000mg of omega-3. Stay tuned for a comprehensive 
                            blog post where I'll delve into how to plan a nutritious, sustainable, flavorful, and balanced diet.
                        </li>

                        <li>
                            <b>Get enough sleep:</b> Sound sleep is essential for physical health, cognitive function, emotional 
                            regulation, and immune function. It supports memory consolidation, learning, and problem-solving 
                            skills while regulating mood and reducing stress. Adequate sleep balances hormone production, 
                            promotes athletic recovery, and supports brain health. Disrupted sleep patterns can lead to obesity, 
                            heart disease, diabetes, mood disorders, and weakened immunity. 
                            So, aim for 7.5 to 8 hours of uninterrupted sleep every night
                        </li>
                    </ol>
                </p>
                <div className="blogsImgContainer">
                    <img src="../assets/blogs/blog_0001/b2.jpg" loading="lazy" alt="" onError={(e)=>e.target.src='../assets/gym.jpg'} className="blogsImg"/>
                </div>
                <h2>
                    Example Schedule
                </h2>
                <p>
                    Let's break down a sample schedule to fit fitness activities into your daily routine:
                    <ul>
                        <li><b>12-hour job:</b> If your job takes up 12 hours, including travel and getting ready time, you've got 12 hours left.</li>
                        <li><b>8 hours of sleep:</b> Aim for 8 hours of sleep each night, leaving 4 hours.</li>
                        <li><b>Fitness time/Family time:</b> If you have a child and spouse, involve them in your fitness activities. Going to the gym or doing exercises together can be enjoyable bonding time. Let's say this takes 1 hour, leaving 3 hours.</li>
                        <li><b>Reading:</b> Spend 30 minutes reading, leaving 2 hours 30 minutes.</li>
                        <li><b>Meditation or quality time with family/friends:</b> Dedicate 30 minutes to mindfulness or connecting with loved ones, leaving 2 hours.</li>
                        <li><b>Eating:</b> Allocate 1 hour for meals, leaving 1 hour.</li>
                        <li><b>Free time:</b> Use this hour to do whatever you enjoy, like watching TV, spending time with family, or taking a nap.</li>
                    </ul>
                </p>
                <p>
                    This schedule is just an example for a typical weekday. You might wonder where the fun fits in, 
                    but once you get into this routine, you'll find enjoyment in it. Plus, you can relax more on weekends. 
                    Weekends offer plenty of time for leisure activities like short trips, hanging out with friends, or indulging in Netflix marathons.
                </p>  

                <h2>
                    Closing Remarks
                </h2>
                <p>
                    I hope I've explained everything clearly. My words might have upset some of you, but my main aim is 
                    to help you understand that fitness is really important, and it's not as hard as it seems to maintain it. 
                    You just need to make these habits part of your routine. At first, it might feel tough, but after 
                    2-3 weeks, you'll find yourself enjoying these habits, and they'll become a natural part of your life. 
                    Remember the 21/90 rule:
                </p>
                <p>
                    <div className="blogsQuotes">
                        "It takes 21 days to form a habit and further 90 days to make it permanent."    
                    </div>
                </p>
                <p>
                    If you stick with it for the first 3 weeks, your life will improve, you'll achieve every aspect of fitness, 
                    and you'll find true happiness.
                </p>
                <hr/>
                <div className="blogsAuthor">
                    <img src="../assets/blogs/blog_0001/b2.jpg" loading="lazy" alt="" onError={(e)=>e.target.src='../assets/gym.jpg'} className="blogsAuthorImg"/>
                    <div>
                        <div className="blogsAuthorText">Author: Vishal Malik</div>
                        <div className="blogsAuthorText light">Published: April 14, 2024</div>
                    </div>
                </div>
            </div>
            <BlogsFooter/>
        </div>
    );
}

export default Blog_0001;