const INITIAL_STATE = {
    user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
    isFetching: false,
    error: null
};

export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case 'LOGIN_START':
            return{
                user: null,
                isFetching: true,
                error: null
            };
        case 'LOGIN_SUCCESS':
            return{
                user: action.payload,
                isFetching: false,
                error: null
            };
        case 'LOGIN_FAILURE':
            return{
                user: null,
                isFetching: false,
                error: action.payload
            };
        case 'LOGOUT':
            return{
                user: null,
                isFetching: false,
                error: null
            }
        default:
            return state;
    }
}