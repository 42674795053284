import './sideBar.css';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {Dumbell, Gamepad, Book, Cube, Palette, LeftArrow, RightArrow, User} from '../../utils/icons';
import { useDispatch, useSelector } from 'react-redux';
import { sideBarState, Logout } from '../../redux/actions';

function SideBar({darkMode=false}){

    const isOpen = useSelector((state)=>state.sideBarState);
    const user = useSelector((state)=>state.auth.user);

    const dispatch = useDispatch();

    const redTheme = ["#FFA37A","#FF7F59","#DB2719","#930B16","#7A0619"];
    const blueTheme = ["#AEC8FC","#85A8F8","#3563E9","#1A37A7","#0A196F"];
    const violetTheme = ["#d4c1f9","#BE9FF6","#925FF0","#5E3A9E","#361F61"]

    const handleThemeChange = (color) => {
        switch(color){
            case "red":
                document.documentElement.style.setProperty('--secondary-0',redTheme[0]);
                document.documentElement.style.setProperty('--secondary-1',redTheme[1]);
                document.documentElement.style.setProperty('--secondary-2',redTheme[2]);
                document.documentElement.style.setProperty('--secondary-3',redTheme[3]);
                document.documentElement.style.setProperty('--secondary-4',redTheme[4]);
                break;
            case "violet":
                document.documentElement.style.setProperty('--secondary-0',violetTheme[0]);
                document.documentElement.style.setProperty('--secondary-1',violetTheme[1]);
                document.documentElement.style.setProperty('--secondary-2',violetTheme[2]);
                document.documentElement.style.setProperty('--secondary-3',violetTheme[3]);
                document.documentElement.style.setProperty('--secondary-4',violetTheme[4]);
                break;
            default:
                document.documentElement.style.setProperty('--secondary-0',blueTheme[0]);
                document.documentElement.style.setProperty('--secondary-1',blueTheme[1]);
                document.documentElement.style.setProperty('--secondary-2',blueTheme[2]);
                document.documentElement.style.setProperty('--secondary-3',blueTheme[3]);
                document.documentElement.style.setProperty('--secondary-4',blueTheme[4]);
                break;
        }
        
    }

    const handleButtonClick = () => {
        if(window.innerWidth <= 800){
            dispatch(sideBarState(!isOpen));
        }
    }

    return (
        <div className={`sideBarContainer ${!isOpen && "close"} ${darkMode && "darkBack"}` }>
            <div className={`sideBarUser ${darkMode && "dark"}`}>
                <User className='sideBarUserIcon'/>
                <span>Hi, { user === null ? "Guest" : user.name}</span>
            </div>
            <div onClick={()=>dispatch(sideBarState(!isOpen))} className='sideBarToggle'>
                {
                    isOpen ?
                    <LeftArrow className='sideBarToggleArrow'/>
                    :
                    <RightArrow className='sideBarToggleArrow right'/>
                }
            </div>
            {isOpen && <div onClick={()=>dispatch(sideBarState(!isOpen))} className="sideBarClose">X</div>}
            {isOpen && <div className={`sideBarHeading ${darkMode && "dark"}`}>EXPLORE</div>}
            <Link to='/blogs' className='linkTo'>
                <div className="sideBarButton" onClick={()=>handleButtonClick()}>
                    <Book className={`sideBarButtonIcon ${darkMode && "dark"}`}/>
                    <div className={`sideBarButtonText ${!isOpen && "close"} ${darkMode && "dark"}` }>Blogs</div>
                </div>
            </Link>
            <Link to='/workouts' className='linkTo'>
                <div className="sideBarButton" onClick={()=>handleButtonClick()}>
                    <Dumbell className={`sideBarButtonIcon ${darkMode && "dark"}`}/>
                    <div className={`sideBarButtonText ${!isOpen && "close"} ${darkMode && "dark"}` }>Workouts</div>
                </div>
            </Link>
            <Link to='/' className='linkTo'>
                <div className="sideBarButton" onClick={()=>handleButtonClick()}>
                    <Gamepad className={`sideBarButtonIcon ${darkMode && "dark"}`}/>
                    <div className={`sideBarButtonText ${!isOpen && "close"} ${darkMode && "dark"}` }>Games</div>
                </div>
            </Link>
            <Link to='/tools' className='linkTo' onClick={()=>handleButtonClick()}>
                <div className="sideBarButton">
                    <Cube className={`sideBarButtonIcon ${darkMode && "dark"}`}/>
                    <div className={`sideBarButtonText ${!isOpen && "close"} ${darkMode && "dark"}` }>Tools</div>
                </div>
            </Link>
            {
                isOpen &&
                <div className='sideBarSettings'>
                    <div>
                        <div className={`sideBarHeading ${darkMode && "dark"}`} style={{marginTop:"40px"}}>PREFERENCES</div>
                        <div className="sideBarTheme">
                            <Palette className={`sideBarButtonIcon ${darkMode && "dark"}`}/>
                            <div className={`sideBarButtonText ${!isOpen && "close"} ${darkMode && "dark"}` }>Theme</div>
                            <div className='themeCircle' style={{backgroundColor:"#3563E9"}} onClick={() => handleThemeChange("blue")}></div>
                            <div className='themeCircle' style={{backgroundColor:"#784DC7"}} onClick={() => handleThemeChange("violet")}></div>
                            <div className='themeCircle' style={{backgroundColor:"#D64751"}} onClick={() => handleThemeChange("red")}></div>
                        </div>
                    </div>

                    <div className='sideBarAuth'>
                    {
                        user === null ?
                        <div className="sideBarAuthButton">
                            <Link to='/login' className='sideBarAuthButtonValue'>
                                Log In
                            </Link>
                            <Link to='/register' className='sideBarAuthButtonValue'>
                                Register
                            </Link>
                        </div>
                        :
                        <div className="sideBarAuthButton">
                            <div className='sideBarAuthButtonValue' onClick={()=>dispatch(Logout())}>
                                Logout
                            </div>
                        </div>
                    }
                    </div>
                </div>   
            }
        </div>
    );
}

export default SideBar;