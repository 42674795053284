import './russianSquatProgram.css';
import React, { useState } from "react";
import MainWrapper from '../../../components/mainWrapper/mainWrapper';
import TextInput from '../../../utils/textInput/textInput';

function RussianSquatProgram(){

    const [rm, setRm] = useState(0);
    const [checked, setChecked] = useState(false);

    const getWeight = (percent) => {
        let x = Math.round((rm*percent)/100);

        if(checked){
            let fv = Math.floor(x/2.5);
            if(x - fv*2.5 < (fv+1)*2.5 - x) return fv*2.5;
            return (fv+1)*2.5;
        }

        return x;
    }

    return(
        <MainWrapper
            component={
                <div className='rspContainer'>
                    <h1>Russian Squat Program</h1>
                    <p>
                        The Russian Squat Program is a tough and organized workout plan aimed at boosting your squat strength 
                        and performance. It comes from Soviet weightlifting techniques and is well-regarded in the strength 
                        training world for its ability to build powerful legs. This program is carefully designed to improve 
                        your squat over a six-week period.
                    </p>
                    <div className='rspCalculatorForm'>
                        <span>Just enter your 1 rep max below and generate the program for yourself</span>
                        <TextInput W='200' H='40' label='Your 1 RM' onChange={(e)=>setRm(e.target.value)} />
                        <span>Round to neared 2.5?
                        <label class="switch">
                            <input 
                                type="checkbox"
                                checked={checked}
                                onChange={()=>setChecked(!checked)}
                            />
                            <span class="slider round"></span>
                        </label>
                        </span>
                    </div>
                    <hr/>
                    <div className='rspCalculatorRoutine'>
                        <div className="rspCalculatorRoutineTable">
                            <h2>Week 1</h2>
                            <div className="rspCalculatorRoutineTableRow">
                                <span>Day 1</span>
                                <span>{getWeight(80)} x 6 sets x 2 reps</span> 
                            </div>
                            <div className="rspCalculatorRoutineTableRow">
                                <span>Day 2</span>
                                <span>{getWeight(80)} x 6 sets x 3 reps</span> 
                            </div>
                            <div className="rspCalculatorRoutineTableRow">
                                <span>Day 3</span>
                                <span>{getWeight(80)} x 6 sets x 2 reps</span> 
                            </div>
                        </div>
                        <div className="rspCalculatorRoutineTable">
                            <h2>Week 2</h2>
                            <div className="rspCalculatorRoutineTableRow">
                                <span>Day 1</span>
                                <span>{getWeight(80)} x 6 sets x 4 reps</span> 
                            </div>
                            <div className="rspCalculatorRoutineTableRow">
                                <span>Day 2</span>
                                <span>{getWeight(80)} x 6 sets x 2 reps</span> 
                            </div>
                            <div className="rspCalculatorRoutineTableRow">
                                <span>Day 3</span>
                                <span>{getWeight(80)} x 6 sets x 5 reps</span> 
                            </div>
                        </div>
                        <div className="rspCalculatorRoutineTable">
                            <h2>Week 3</h2>
                            <div className="rspCalculatorRoutineTableRow">
                                <span>Day 1</span>
                                <span>{getWeight(80)} x 6 sets x 2 reps</span> 
                            </div>
                            <div className="rspCalculatorRoutineTableRow">
                                <span>Day 2</span>
                                <span>{getWeight(80)} x 6 sets x 6 reps</span> 
                            </div>
                            <div className="rspCalculatorRoutineTableRow">
                                <span>Day 3</span>
                                <span>{getWeight(80)} x 6 sets x 2 reps</span> 
                            </div>
                        </div>
                        <div className="rspCalculatorRoutineTable">
                            <h2>Week 4</h2>
                            <div className="rspCalculatorRoutineTableRow">
                                <span>Day 1</span>
                                <span>{getWeight(85)} x 5 sets x 5 reps</span> 
                            </div>
                            <div className="rspCalculatorRoutineTableRow">
                                <span>Day 2</span>
                                <span>{getWeight(80)} x 6 sets x 2 reps</span> 
                            </div>
                            <div className="rspCalculatorRoutineTableRow">
                                <span>Day 3</span>
                                <span>{getWeight(90)} x 4 sets x 4 reps</span> 
                            </div>
                        </div>
                        <div className="rspCalculatorRoutineTable">
                            <h2>Week 5</h2>
                            <div className="rspCalculatorRoutineTableRow">
                                <span>Day 1</span>
                                <span>{getWeight(80)} x 6 sets x 2 reps</span> 
                            </div>
                            <div className="rspCalculatorRoutineTableRow">
                                <span>Day 2</span>
                                <span>{getWeight(95)} x 3 sets x 3 reps</span> 
                            </div>
                            <div className="rspCalculatorRoutineTableRow">
                                <span>Day 3</span>
                                <span>{getWeight(80)} x 6 sets x 2 reps</span> 
                            </div>
                        </div>
                        <div className="rspCalculatorRoutineTable">
                            <h2>Week 6</h2>
                            <div className="rspCalculatorRoutineTableRow">
                                <span>Day 1</span>
                                <span>{getWeight(100)} x 2 sets x 2 reps</span> 
                            </div>
                            <div className="rspCalculatorRoutineTableRow">
                                <span>Day 2</span>
                                <span>{getWeight(80)} x 6 sets x 2 reps</span> 
                            </div>
                            <div className="rspCalculatorRoutineTableRow">
                                <span>Day 3</span>
                                <span>{getWeight(105)} x 1 set x 1 rep</span> 
                            </div>
                        </div>
                    </div>

                    <h2>Getting Started</h2>
                    <p>
                        <ol>
                            <li>
                                <b>Determine Your One-Rep Max (1RM):</b> Before starting, establish your current 1RM for the squat. 
                                This is the maximum weight you can lift for one full squat with proper form.
                            </li>
                            <li>
                                <b>Schedule Your Workouts:</b> Plan to squat three times per week. For example, you could 
                                squat on Monday, Wednesday, and Friday.
                            </li>
                        </ol>
                    </p>

                    <h2>Program Structure</h2>
                    <p>
                        <b>Weeks 1-3: Volume Phase</b>
                        <ul>
                            <li>
                                <b>Week 1:</b>
                                <ul>
                                    <li><b>Day 1:</b> 6 sets of 2 reps at 80% of your 1RM</li>
                                    <li><b>Day 2:</b> 6 sets of 3 reps at 80% of your 1RM</li>
                                    <li><b>Day 3:</b> 6 sets of 2 reps at 80% of your 1RM</li>
                                </ul>
                            </li>
                            <li>
                                <b>Week 2:</b>
                                <ul>
                                    <li><b>Day 1:</b> 6 sets of 4 reps at 80% of your 1RM</li>
                                    <li><b>Day 2:</b> 6 sets of 2 reps at 80% of your 1RM</li>
                                    <li><b>Day 3:</b> 6 sets of 5 reps at 80% of your 1RM</li>
                                </ul>
                            </li>
                            <li>
                                <b>Week 3:</b>
                                <ul>
                                    <li><b>Day 1:</b> 6 sets of 2 reps at 80% of your 1RM</li>
                                    <li><b>Day 2:</b> 6 sets of 6 reps at 80% of your 1RM</li>
                                    <li><b>Day 3:</b> 6 sets of 2 reps at 80% of your 1RM</li>
                                </ul>
                            </li>
                        </ul>
                    </p>
                    <p>
                        <b>Weeks 4-6: Intensity Phase</b>
                        <ul>
                            <li>
                                <b>Week 4:</b>
                                <ul>
                                    <li><b>Day 1:</b> 5 sets of 5 reps at 85% of your 1RM</li>
                                    <li><b>Day 2:</b> 6 sets of 2 reps at 80% of your 1RM</li>
                                    <li><b>Day 3:</b> 4 sets of 4 reps at 90% of your 1RM</li>
                                </ul>
                            </li>
                            <li>
                                <b>Week 5:</b>
                                <ul>
                                    <li><b>Day 1:</b> 6 sets of 2 reps at 80% of your 1RM</li>
                                    <li><b>Day 2:</b> 3 sets of 3 reps at 95% of your 1RM</li>
                                    <li><b>Day 3:</b> 6 sets of 2 reps at 80% of your 1RM</li>
                                </ul>
                            </li>
                            <li>
                                <b>Week 6:</b>
                                <ul>
                                    <li><b>Day 1:</b> 2 sets of 2 reps at 100% of your 1RM</li>
                                    <li><b>Day 2:</b> 6 sets of 2 reps at 80% of your 1RM</li>
                                    <li><b>Day 3:</b> 1 set of 1 rep at 105% of your 1RM (attempt a new PR)</li>
                                </ul>
                            </li>
                        </ul>
                    </p>

                    <h2>Tips for Success</h2>
                    <p>
                        <ol>
                        <li>
                            <b>Warm Up Properly:</b>
                            <ul>
                                <li>Ensure you perform a thorough warm-up before each session, including dynamic stretches 
                                    and lighter sets of squats.</li>
                            </ul>
                        </li>

                        <li>
                            <b>Rest and Recovery:</b>
                            <ul>
                                <li>Allow sufficient rest between sets, typically 2-3 minutes.</li>
                                <li>Focus on proper nutrition, hydration, and sleep to support recovery.</li>
                            </ul>
                        </li>

                        <li>
                            <b>Form and Technique:</b>
                            <ul>
                                <li>Maintain good form throughout the program to prevent injuries and maximize gains.</li>
                                <li>Consider recording your lifts or working with a coach to ensure proper technique.</li>
                            </ul>
                        </li>

                        <li>
                            <b>Listen to Your Body:</b>
                            <ul>
                                <li>If you experience pain or excessive fatigue, adjust the weights or take additional rest days as needed.</li>
                            </ul>
                        </li>

                        <li>
                            <b>Progress Tracking:</b>
                            <ul>
                                <li>Keep a detailed log of your workouts, including the weights lifted and how you felt during each session.</li>
                            </ul>
                        </li>

                        <li>
                            <b>Supplementary Work:</b>
                            <ul>
                                <li>While the focus is on squats, consider adding complementary exercises like deadlifts, lunges, 
                                    and core work to support overall strength.</li>
                            </ul>
                        </li>
                        </ol>
                    </p>

                    <h2>Adjustments</h2>
                    <p>
                        <ol>
                            <li>
                                <b>Weight Adjustments:</b> If you find the weights too challenging or too easy, you can adjust the percentage of your 1RM accordingly.
                            </li>
                            <li>
                                <b>Recovery Days:</b> If needed, incorporate active recovery days with light activities like walking, stretching, or yoga.
                            </li>
                        </ol>
                    </p>

                    <h2>Example Schedule</h2>
                    <p>
                        <ul>
                            <li><b>Monday:</b> Squat (following the day's set/reps)</li>
                            <li><b>Tuesday:</b> Upper body</li>
                            <li><b>Wednesday:</b> Squat</li>
                            <li><b>Thursday:</b> Rest or active recovery</li>
                            <li><b>Friday:</b> Squat</li>
                            <li><b>Saturday:</b> Upper body</li>
                            <li><b>Sunday:</b> Rest</li>
                        </ul>
                    </p>
                </div>
            }
        />
    );
}

export default RussianSquatProgram;