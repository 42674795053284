import React from "react";
import Blogs from "./domains/blogs/blogs";
import Blog_0001 from "./domains/blogs/blogsContent/blog_0001/blog_0001";
import BmiCalculator from "./domains/tools/bmiCalculator/bmiCalculator";
import BmrCalculator from "./domains/tools/bmrCalculator/bmrCalculator";
import Tools from "./domains/tools/tools";
import RussianSquatProgram from "./domains/workouts/russianSquatProgram/russianSquatProgram";
import Workouts from "./domains/workouts/workouts";
import Login from "./login/login";
import PrivacyPage from "./privacyPage/privaryPage";
import Register from "./register/register";
import Blog_0002 from "./domains/blogs/blogsContent/blog_0002/blog_0002";
import Blog_0003 from "./domains/blogs/blogsContent/blog_0003/blog_0003";

const blogs = [
    {
        title: "Blogs",
        path: "/blogs",
        element: <Blogs/>
    },
    {
        title: "Understanding the aspects of fitness to achieve true happiness in corporate lifestyle",
        path: "/blogs/Understanding-the-aspects-of-fitness-to-achieve-True-Happiness-in-Corporate-Lifestyle",
        element: <Blog_0001/>
    },
    {
        title: "Top 10 Protein-Packed Foods for Vegetarians and Non-Vegetarians",
        path: "/blogs/Top-10-Protein-Packed-Foods-for-Vegetarians-and-Non-Vegetarians",
        element: <Blog_0002/>
    },
    {
        title: "Top 10 Protein-Packed Foods for Vegetarians and Non-Vegetarians",
        path: "/blogs/Unlocking-the-Power-of-Sleep-for-Optimal-Health-and-Peak-Performance",
        element: <Blog_0003/>
    }
]

const tools = [
    {
        title: "Tools",
        path: "/tools",
        element: <Tools/>
    },
    {
        title: "BMR Calculator",
        path: "/tools/bmr",
        element: <BmrCalculator/>
    },
    {
        title: "BMI Calculator",
        path: "/tools/bmi",
        element: <BmiCalculator/>
    }
]

const workouts = [
    {
        title: "Workouts",
        path: "/workouts",
        element: <Workouts/>
    },
    {
        title: "Russian squat program",
        path: "/workouts/russian-squat-program",
        element: <RussianSquatProgram/>
    }
]

const auth = [
    {
        title: "Login",
        path: "/login",
        element: <Login/>
    },
    {
        title: "Register",
        path: "/register",
        element: <Register/>
    }
]

const others = [
    {
        title: "Privacy Policy",
        path: "/privacy-policy",
        element: <PrivacyPage/>
    }
]

export const AllPaths = () => {
    const paths = [];
    const allPaths = paths.concat(blogs,tools,workouts,auth,others);
    return allPaths;
};