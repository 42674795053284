import { combineReducers } from "redux";
import gymsReducer from "./gymsReducer";
import filteredGymsReducer from "./filteredGymsReducer";
import sideBarStateReducer from "./sideBarStateReducer";
import AuthReducer from "./AuthReducer";

export default combineReducers({
    gyms: gymsReducer,
    filteredGyms: filteredGymsReducer,
    sideBarState: sideBarStateReducer,
    auth: AuthReducer
});