import './blogsHeader.css';
import React from "react";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { sideBarState } from '../../redux/actions';
import { Search, Menu, Logo } from '../../utils/icons';

function BlogsHeader(){

    const isOpen = useSelector((state)=>state.sideBarState);
    const dispatch = useDispatch();

    return (
        <div>
            <div className='blogsHeader'>
                <div className="blogsHeaderLogo">
                    <Logo className='logo'/>
                    <div className="logoText">
                        <div>ealthVyu</div>
                        <div className='logoSlang'>fitness haven</div>
                    </div>
                </div>
                <div className="blogsHeaderOptions">
                    <Link to='/blogs' className='linkTo'>
                        <div className='blogsHeaderRoutes'>Blogs</div>
                    </Link>
                    <Link to='/workouts' className='linkTo'>
                        <div className='blogsHeaderRoutes'>Workouts</div>
                    </Link>
                    <Link to='/tools' className='linkTo'>
                        <div className='blogsHeaderRoutes'>Tools</div>
                    </Link>
                </div>
                <div className="blogsHeaderButtons">
                    <Link to='/login' className='linkTo'>
                        <button>Login</button>
                    </Link>
                    <Link to='/register' className='linkTo'>
                        <button>Register</button>
                    </Link>
                </div>
            </div>
            <div className="blogsHeaderSS">
                {/* <img src="../assets/icons/menu.svg" alt="" className='headerIcon'/> */}
                <div className='blogsHeaderIcon' onClick={()=>dispatch(sideBarState(!isOpen))}>
                    <Menu H="30" W="30" style={{fill: "white"}}/>
                </div>
                <div className="blogsHeaderLogo">
                    <Logo className='logo'/>
                    <div className="logoText">
                        <div>ealthVyu</div>
                        <div className='logoSlang'>fitness haven</div>
                    </div>
                </div>
                <Search H="30" W="30" className='blogsHeaderIcon'/>
            </div>
        </div>
    );
}

export default BlogsHeader;