import './App.css';
import React, { useEffect } from 'react';
import Login from './login/login';
import Register from './register/register';
import Home from './homePage/home';
import { Routes,Route } from "react-router-dom";
import Tools from './domains/tools/tools';
import { useSelector } from 'react-redux';
import BmiCalculator from './domains/tools/bmiCalculator/bmiCalculator';
import LandingPage from './landingPage/landingPage';
import BmrCalculator from './domains/tools/bmrCalculator/bmrCalculator';
import Blogs from './domains/blogs/blogs';
import Blog_0001 from './domains/blogs/blogsContent/blog_0001/blog_0001';
import CookieConsent, { Cookies } from "react-cookie-consent";
import PrivacyPage from './privacyPage/privaryPage';
import Workouts from './domains/workouts/workouts';
import RussianSquatProgram from './domains/workouts/russianSquatProgram/russianSquatProgram';
import { AllPaths } from './paths';

function App() {

  const { user } = useSelector((state) => state.auth);

  const blogs = [
    {
      path: "/blogs/Understanding-the-aspects-of-fitness-to-achieve-True-Happiness-in-Corporate-Lifestyle",
      element: <Blog_0001/>
    }
  ];

  return (
    <div className='App'>
        <CookieConsent
          location="bottom"
          buttonText="I agree"
          cookieName="myAppCookieConsent"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "var(--primary-1)", fontSize: "16px", backgroundColor: "var(--secondary-2)" }}
          expires={150}
        >
          This website uses cookies to enhance the user experience.
        </CookieConsent>
        <Routes>
          <Route path='/' element={ <LandingPage/> }/>
          {/* <Route path='login' element={<Login/>}/>
          <Route path='register' element={<Register/>}/>
          <Route path='clubs' element={<ClubsHome/>}/>
          <Route path='gyms' element={<Home/>}/>
          <Route path='workouts' element={<Workouts/>}/>
          <Route path='workouts/russian-squat-program' element={<RussianSquatProgram/>}/>
          <Route path='tools' element={<Tools/>}/>
          <Route path='tools/bmi' element={<BmiCalculator/>}/>
          <Route path='tools/bmr' element={<BmrCalculator/>}/>
          <Route path='clubs/admin' element={<ClubsAdmin/>}/>
          <Route path='/privacy-policy' element={<PrivacyPage/>}/>
          <Route path='blogs' element={<Blogs/>}/> */}
          {
            AllPaths().map((b)=>(
              <Route path={b.path} element={b.element}/>
            ))
          }
        </Routes>
        {/* <Routes>
          <Route path='/' element={user ? <ClubsHome/> : <ClubsHome/> }/>
          <Route path='login' element={<ClubsHome/>}/>
          <Route path='register' element={<ClubsHome/>}/>
          <Route path='blogs' element={<ClubsHome/>}/>
          <Route path='clubs' element={<ClubsHome/>}/>
          <Route path='clubs/admin946625' element={<ClubsAdmin/>}/>
        </Routes>      */}
    </div>
  );
}

export default App;
