import './landingPage.css';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { sideBarState } from '../redux/actions';
import { Search, Menu, Logo } from '../utils/icons';
import DecorativeImage from './decorativeImage';
import SideBar from '../components/sideBar/sideBar';
import BlogsFooter from '../components/blogsFooter/blogsFooter';


function LandingPage() {

    const isOpen = useSelector((state)=>state.sideBarState);
    const dispatch = useDispatch();


    return (
        <div className='landingContainer'>
            <div className='landingHeader'>
                <div className="headerLogo" style={{color:"#DB2719"}}>
                    <Logo className='logoH red'/>
                    <div className="logoHText">
                        <div>ealthVyu</div>
                        <div className='logoHSlang'>fitness haven</div>
                    </div>
                </div>
                <div className="landingHeaderOptions">
                    <Link to='/blogs' className='linkTo'>
                        <div className='landingHeaderRoutes'>Blogs</div>
                    </Link>
                    <Link to='/workouts' className='linkTo'>
                        <div className='landingHeaderRoutes'>Workouts</div>
                    </Link>
                    <Link to='/tools' className='linkTo'>
                        <div className='landingHeaderRoutes'>Tools</div>
                    </Link>
                </div>
                <div className="landingHeaderButtons">
                    <Link to='/login' className='linkTo'>
                        <button>Login</button>
                    </Link>
                    <Link to='/register' className='linkTo'>
                        <button>Register</button>
                    </Link>
                </div>
            </div>
            <div className="landingHeaderSS">
                {/* <img src="../assets/icons/menu.svg" alt="" className='headerIcon'/> */}
                <div className='landingHeaderIcon' onClick={()=>dispatch(sideBarState(!isOpen))}>
                    <Menu H="30" W="30" style={{fill: "white"}}/>
                </div>
                <div className="headerLogo" style={{color:"#DB2719"}}>
                    <Logo className='logoH red'/>
                    <div className="logoHText">
                        <div>ealthVyu</div>
                        <div className='logoHSlang'>fitness haven</div>
                    </div>
                </div>
                <Search H="30" W="30" className='landingHeaderIcon'/>
            </div>
            {isOpen && <div className="landingSideBarOverlay" onClick={()=>dispatch(sideBarState(!isOpen))}/>}
            <div className="landingSideBar">
                <SideBar darkMode={true} />
            </div>
            <div className="landingImageSlider">
                <div className="slide">
                    <div className="landingImageCaption">
                        <span>FITNESS</span>&thinsp; redefined
                        <br/>
                        for your<span>&thinsp; LIFESTYLE</span>
                    </div>
                    <div className='landingImageContainer'>
                        <img src="/assets/landing_1.jpg" style={{objectPosition: "75% 50%"}}/>
                        <div className="landingImageOverlay_h" />
                    </div>
                </div>
                <div className="slide">
                    <div className="landingImageCaption">
                        Revitalize your<span>&thinsp; FITNESS</span>
                        <br/>
                        &emsp; &thinsp;<span>JOURNEY</span> &thinsp; today
                    </div>
                    <div className='landingImageContainer'>
                        <img src="/assets/landing_3.jpg" style={{objectPosition: "75% 50%"}}/>
                        <div className="landingImageOverlay_h" />
                    </div>
                </div>
                <div className="slide">
                    <div className="landingImageCaption">
                        Transfrom your &ensp;<span>BODY</span>
                        <br/>
                        &emsp; <span>Transform</span>&ensp;your life
                    </div>
                    <div className='landingImageContainer'>
                        <img src="/assets/landing_4.jpg" style={{objectPosition: "100% 50%"}}/>
                        <div className="landingImageOverlay_h" />
                    </div>
                </div>
                <div className="slide">
                    <div className="landingImageCaption">
                        Discover a &thinsp;<span>STRONGER,</span>
                        <br/>
                        &emsp; <span>HEALTHIER</span>&thinsp; You !
                    </div>
                    <div className='landingImageContainer'>
                        <img src="/assets/landing_2.jpg"/>
                        <div className="landingImageOverlay_h" />
                    </div>
                </div>
                <div className="slide">
                    <div className="landingImageCaption">
                        <span>FITNESS</span>&thinsp; redefined
                        <br/>
                        for your<span>&thinsp; LIFESTYLE</span>
                    </div>
                    <div className='landingImageContainer'>
                        <img src="/assets/landing_1.jpg"/>
                        <div className="landingImageOverlay_h" />
                    </div>
                </div>
            </div>
            <div className="landingFindWhatMovesYou">
                <div className='landingHeadings'>Find what moves you</div>
                <div className="landingWorkoutTypes">
                    <div className="landingWorkoutImage">
                        <img src="/assets/landing_w_1.jpg" alt="" />
                        <div className="landingWorkoutImgText">
                            <h1>GYM</h1>
                            <span>Expect a heart-pumping workout that will leave you feeling energized and accomplished. 
                                Our supportive community of like-minded individuals.
                            </span>
                        </div>
                        <div className="landingWorkoutImgOverlay" />
                    </div>
                    <div style={{display: "flex", gap:"20px"}}>
                        <div className="landingWorkoutImage_1">
                            <img src="/assets/landing_w_2.jpg" alt="" />
                            <h1>ZUMBA</h1>
                            <div className="landingWorkoutImg_1_Overlay" />
                        </div>
                        <div className="landingWorkoutImage_1">
                            <img src="/assets/landing_w_3.jpg" alt="" />
                            <h1>YOGA</h1>
                            <div className="landingWorkoutImg_1_Overlay" />
                        </div>
                        <div className="landingWorkoutImage_1">
                            <img src="/assets/landing_w_4.jpg" alt="" />
                            <h1>DANCE</h1>
                            <div className="landingWorkoutImg_1_Overlay" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="landingMakeItYourLife">
                <div className='landingHeadings'>Make it your lifestyle</div>
                <div className='landingHeadings small'>
                    Theory of 21/90 rule states,
                    <br/>it takes 21 days to form a habit and
                    <br/>further 90 days to make it permanent.
                </div>
                <DecorativeImage imgUrl={'/assets/landing_w_5.jpg'}/>
            </div>
            <div className="landingHabits">
                <div className='landingHeadings responsive'>Develop Positive Habits</div>
                <div className="landingHabitsCards">
                    <div className="landingHabitsCardsCard">
                        <DecorativeImage imgUrl={'/assets/habits_1.jpg'} S={0.8}/>
                        <div className="text sz_24 clr_w mr_5">
                            &emsp;Reading
                        </div>
                        <div className='text sz_16 clr_w'>
                            <ul>
                                <li>We prioritize holistic fitness.</li>
                                <li>Brain training is as vital as physical training.</li>
                                <li>Reading daily expands our perspectives.</li>
                                <li>Reading daily fosters lifelong learning.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="landingHabitsCardsCard">
                        <DecorativeImage imgUrl={'/assets/habits_2.jpg'} S={0.8}/>
                        <div className="text sz_24 clr_w mr_5">
                            &emsp;Sunlight 
                        </div>
                        <div className='text sz_16 clr_w'>
                            <ul>
                                <li>Every day, greet the sun's radiance.</li>
                                <li>Sunlight fuels energy, lifts moods naturally.</li>
                                <li>Bask in sunlight, embrace its warmth.</li>
                                <li>In sunlight, find nature's healing embrace.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="landingHabitsCardsCard">
                        <DecorativeImage imgUrl={'/assets/habits_3.jpg'} S={0.8}/>
                        <div className="text sz_24 clr_w mr_5">
                            &emsp;Sleeping
                        </div>
                        <div className='text sz_16 clr_w'>
                            <ul>
                                <li>Timely sleep fosters optimal health.</li>
                                <li>Consistent sleep schedule promotes balance.</li>
                                <li>Quality sleep enhances mind and body.</li>
                                <li>Listen to your body's call for rest.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <BlogsFooter/>
        </div>
    )
}

export default LandingPage;