import './workouts.css';
import React from "react";
import Header from "../../components/header/header";
import SideBar from "../../components/sideBar/sideBar";
import { useState } from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { sideBarState } from "../../redux/actions";
import WorkoutsCard from './workoutsCard';

function Workouts(){

    const dispatch = useDispatch();
    const isOpen = useSelector((state)=>state.sideBarState);

    return (
        <div className="workoutsContainer">
            {isOpen && <div className="overlay" onClick={()=>dispatch(sideBarState(!isOpen))}/>}
            <div className="header">   
                <Header/>
            </div>
            <div className='workoutsContent'>
                <SideBar/>
                <div className='workoutsList'>
                    <Link to='/workouts/russian-squat-program' className='workoutsLinks'>
                        <WorkoutsCard/>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Workouts;