import './bmrCalculator.css';
import React, { useRef, useState } from 'react';
import SideBar from '../../../components/sideBar/sideBar';
import Header from '../../../components/header/header';
import { useDispatch, useSelector } from 'react-redux';
import { sideBarState } from '../../../redux/actions';
import { Back } from '../../../utils/icons';
import { Link } from 'react-router-dom';


function BmrCalculator() {

    const dispatch = useDispatch();
    const isOpen = useSelector((state)=>state.sideBarState);

    const [bmr, setBmr] = useState();
    const [weightUnit, setWeightUnit] = useState('kg');
    const [heightUnit, setHeightUnit] = useState('cm');
    
    const [error, setError] = useState({
        height: '',
        weight: '',
        age: ''
    });

    const weight = useRef(0);
    const height = useRef(0);
    const age = useRef(0);
    const gender = useRef();

    const validations = () => {
        console.log(height.current.value);
        let isValid = true;
        let h='';
        let w='';
        let a='';
        if(height.current.value === null || height.current.value === 0 || height.current.value === ""){
            h='please provide valid Height';
            isValid = false;
        }
        if(weight.current.value === null || weight.current.value === 0 || weight.current.value === ""){
            w='please provide valid Weight';
            isValid = false;
        }
        if(age.current.value === null || age.current.value === 0 || age.current.value === "" || age.current.value <= 0){
            a='please provide valid Age';
            isValid = false;
        }
        setError({
            age: a,
            height: h,
            weight: w
        });
        return isValid;
    }

    const calculateBMR = (e) => {
        if(!validations()) return;
        
        let h = height.current.value;
        let w = weight.current.value;
        let a = age.current.value;

        if(weightUnit === 'lbs') w=w*0.453592;
        if(heightUnit === 'in') h=h*2.54;
        
        let bmrValue;
        if (gender.current.value === 'male') {
            bmrValue = 88.362 + (13.397 * w) + (4.799 * h) - (5.677 * a);
        } else {
            bmrValue = 447.593 + (9.247 * w) + (3.098 * h) - (4.330 * a);
        }
        setBmr(bmrValue.toFixed(2));
        
        e.preventDefault();
    };

    return (
        <div className='bmiCalcContainer'>
            {isOpen && <div className="overlay" onClick={()=>dispatch(sideBarState(!isOpen))}/>}
            <div className="header">   
                <Header/>
            </div>
            <div className='bmiCalcContent'>
                <SideBar/>
                <div className="bmiCalcFormContainer">
                    <div className='toolsBack'>
                        <Link to='/tools' className='calcLink'>
                            <Back className='toolsBackIcon'/> back to tools
                        </Link>
                    </div>
                    <div className="bmiCalcHeading">BMR Calculator</div>
                    <div className='bmiInfo'>
                        Basal Metabolic Rate (BMR) represents the minimum energy your body needs to sustain 
                        vital functions like breathing, circulation, and cell repair while at rest. 
                        It's the foundation of your daily calorie expenditure, independent of activity levels. 
                        Calculated based on factors like age, gender, weight, and height, BMR provides insight 
                        into your body's fundamental energy requirements. Knowing your BMR helps tailor nutrition 
                        and fitness plans, aiding weight management and overall health goals. 
                        Understanding this metabolic baseline guides decisions on calorie intake, 
                        ensuring sufficient energy for bodily functions while supporting efforts to maintain, 
                        gain, or lose weight effectively.
                    </div>
                    <form className='checkBmiForm'>
                        <div className='formInputContainer'>
                            <span className='error text sz_14 clr_w'>{error.age}</span> 
                            <label className='bmiFormLabel'>
                                Age
                            </label>
                            <input className = 'formInputField' 
                                type="number" 
                                step="1"
                                min="15"
                                ref={age}
                                placeholder="years"
                                required
                            />
                        </div>
                        <div className='formInputContainer'>
                            <label className='bmiFormLabel'>
                                Gender   
                            </label>
                            <select ref={gender}>
                                <option value='male'>male</option>
                                <option value='female'>female</option>
                            </select>
                        </div>
                        <div className='formInputContainer'>
                            <span className='error text sz_14 clr_w'>{error.height}</span>
                            <label className='bmiFormLabel'>
                                Height  
                                <label className='bmiFormRadioButton'>
                                    cm
                                    <input type="radio" value="cm"
                                        checked={heightUnit === 'cm'}
                                        onChange={()=>setHeightUnit('cm')}
                                    />
                                </label>
                                <label className='bmiFormRadioButton'>
                                    in
                                    <input type="radio" value="in"
                                        checked={heightUnit === 'in'}
                                        onChange={()=>setHeightUnit('in')}
                                    />
                                </label>    
                            </label>
                            <input className = 'formInputField' 
                                type="number" 
                                step="0.01"
                                ref={height}
                                placeholder={heightUnit}
                                required
                            />
                        </div>
                        <div className='formInputContainer'>
                            <span className='error text sz_14 clr_w'>{error.weight}</span>
                            <label className='bmiFormLabel'>
                                Weight  
                                <label className='bmiFormRadioButton'>
                                    kg
                                    <input type="radio" value="kg"
                                        checked={weightUnit === 'kg'}
                                        onChange={()=>setWeightUnit('kg')}
                                    />
                                </label>
                                <label className='bmiFormRadioButton'>
                                    lbs
                                    <input type="radio" value="lbs"
                                        checked={weightUnit === 'lbs'}
                                        onChange={()=>setWeightUnit('lbs')}
                                    />
                                </label>
                            </label>
                            <input className = 'formInputField' 
                                type="number"
                                step="0.01"
                                ref={weight}
                                placeholder={weightUnit}
                                required
                            />
                        </div>
                        <button className='bmiFormButton' onClick={calculateBMR}>Check BMR</button>
                        { bmr && <div className='bmiValue'>{bmr}</div>}
                    </form>
                    <div className="bmrChart">
                        <div className="bmrChartRow">
                            <span style={{backgroundColor: 'var(--secondary-3)',color: "white",fontWeight: '500'}}>Activity Level</span>
                            <span style={{backgroundColor: 'var(--secondary-3)',color: "white",fontWeight: '500'}}>Calories</span>
                        </div>
                        <div className="bmrChartRow">
                            <span>Sedentary (little or no exercise)</span>
                            <span>{Math.round(bmr*1.2)}</span>
                        </div>
                        <div className="bmrChartRow">
                            <span>Lightly active (light exercise/sports 1-3 days a week)</span>
                            <span>{Math.round(bmr*1.375)}</span>
                        </div>
                        <div className="bmrChartRow">
                            <span>Moderately active (moderate exercise/sports 3-5 days a week)</span>
                            <span>{Math.round(bmr*1.55)}</span>
                        </div>
                        <div className="bmrChartRow">
                            <span>Very active (hard exercise/sports 6-7 days a week)</span>
                            <span>{Math.round(bmr*1.725)}</span>
                        </div>
                        <div className="bmrChartRow">
                            <span>Extra active (very hard exercise/sports & physical job or 2x training)</span>
                            <span>{Math.round(bmr*1.9)}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BmrCalculator;