import './blogsCard.css';
import React from 'react';


function BlogsCard({blog}){

    return(
        <div className="blogsCardContainer">
            <img src={`../assets/blogs/${blog.img}`} loading="lazy" alt="" onError={(e)=>e.target.src='../assets/gym.jpg'} className="blogsCardImg"/>
            <div className="blogsCardDetails">
                <div className={`blogsCardTag ${blog.color} text sz_12 wt_6 clr_w`}>{blog.tag}</div>
                <h1>{blog.heading}</h1>
                <h2>{blog.subHeading}</h2>
                <h3>Author: {blog.author}</h3>
            </div>
        </div>
    );
};

export default BlogsCard; 